import {Injectable} from '@angular/core'
import {environment} from "../../../environments/environment";
import {BehaviorSubject, map, merge, Observable, Subscription} from "rxjs";
import {Child} from "../../types/interfaces";

@Injectable({
  providedIn: 'root',
})
export class PersistenceService {

  set(key: string, data: unknown): void {
    try {
      localStorage.setItem(key, JSON.stringify(data))
    } catch (e) {
      console.error('Error saving to local storage', e)
    }
  }

  get(key: string): unknown {
    try {
      const localStorageItem = localStorage.getItem(key)
      return localStorageItem && localStorageItem != 'undefined' ? JSON.parse(localStorageItem) : null
    } catch (e) {
      console.error('Error getting from local storage', e)
      return null
    }
  }

  remove(key: string){

    localStorage.removeItem(key)

  }

  clear(){
      let currentLang = localStorage.getItem('language') ?? 'uk';
      localStorage.clear();

      localStorage.setItem('language', currentLang);

  }

  isLoggedIn(): boolean {

    return this.getToken() !== null;

  }


  getToken(): string {
    return this.get('token') as string;
  }

  setToken(token: string): void {
    this.set('token', token);
  }


  getChildren(): Child[] {
    return this.get('children') as Child[];
  }

  setChildren(token: string): void {
    this.set('children', token);
  }

}
