import {Injectable} from "@angular/core";
import {PersistenceService} from "./persistence.service";
import {TranslocoService} from "@jsverse/transloco";

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private activeLanguage: string;

  constructor(
    private persistenceService: PersistenceService,
    private translocoService: TranslocoService

  ) {
      this.activeLanguage = this.getLanguage() ?? this.translocoService.getActiveLang();
      this.translocoService.setActiveLang(this.activeLanguage);
      this.setLanguage(this.activeLanguage);
  }

  public setLanguage(lang: string): void {
    // this.persistenceService.set('language', lang);
      localStorage.setItem('language',lang);
  }

  // public getLanguage() {
  //   return this.persistenceService.get('language');
  // }

   public getLanguage():string {
        return localStorage.getItem('language') ?? 'uk';
    }
}
