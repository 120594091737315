

//export let API_HOST = "http://gf.tec/api/";
import {environment} from "../environments/environment";

export let WEB_HOST = environment.webUrl;
export let API_HOST = environment.apiUrl;
export let HOST = environment.host;

export let API_URL = API_HOST
export let WEB_URL = WEB_HOST
export let APP_HOST = HOST

export let API_KEY = "42|Iho0KiLujUFobKpQqW6CzlVE3D0tFbTmLz9FY0KA";

