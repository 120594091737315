import {
    provideTransloco, Translation, TranslocoLoader,
    TranslocoModule
} from '@jsverse/transloco';
import {Injectable, NgModule} from '@angular/core';

import { environment } from '../environments/environment';
import {HttpClient} from "@angular/common/http";
import {map, of, tap} from "rxjs";
import {API_URL} from "../providers/config";

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient,) {
    }

    getTranslation(lang: string) {
        // return this.http.get<Translation>(`/assets/i18n/${lang}.json`);

        if(localStorage.getItem(`langData_${lang}`) && localStorage.getItem('langVersionApp') === localStorage.getItem('langVersionApi')){
            return of(JSON.parse(localStorage.getItem(`langData_${lang}`) ?? '{}'));
        }

        let apiUrl = `${API_URL}/transloco?lang=${lang}`;

        return this.http.get<Translation>(apiUrl)
            // return this.http.get<Translation>(`/assets/i18n/${lang}.json?v=${environment.languageVersion}`)
            .pipe(
                tap(response => {
                    let jsonString = response['data'].replace(/\n\t/g, '').replace(/\n/g, '');
                    let jsonObject = JSON.parse(jsonString);
                    localStorage.setItem(`langData_${lang}`, JSON.stringify(jsonObject));
                    localStorage.setItem('langVersionApp', JSON.stringify(response['version']));

                    // localStorage.setItem('langVersion', environment.languageVersion);

                }),
                map(response => {
                    let jsonString = response['data'].replace(/\n\t/g, '').replace(/\n/g, '');
                    return JSON.parse(jsonString);
                })
            );

    }
}


@NgModule({
  exports: [ TranslocoModule ],
  providers: [
      provideTransloco({
        config: {
          availableLangs: ['en', 'uk'],
          defaultLang: 'uk',
          fallbackLang: 'uk',
          // Remove this option if your application doesn't support changing language in runtime.
          reRenderOnLangChange: true,
          prodMode: environment.production,
        },
        loader: TranslocoHttpLoader,
      }),
  ],
})
export class TranslocoRootModule {}
