import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {debounceTime, map, Observable, of, switchMap, take, tap} from "rxjs";
import {AuthApiResponse} from "../../types/apiResponse.interface";
import {API_URL} from "../../../providers/config";
import {TranslocoService} from "@jsverse/transloco";
import {TranslocoHttpLoader} from "../../transloco-loader";
import {LocaleService} from "./locale.service";
import {MyGreenCountry} from "./my-greencountry";

const apiUrl = API_URL;

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private activeLangs:string;

    constructor(
        private http: HttpClient,
        private translocoService: TranslocoService,
        private translocoHttpLoader: TranslocoHttpLoader,
        private localeService: LocaleService,
    ) {

        this.translocoService.langChanges$.subscribe(lang => {
            this.activeLangs = lang ?? localeService.getLanguage();
            localStorage.setItem('language', this.activeLangs);
        });
    }


    public sendApiRequest(method: string, postData: any, typeMethod: string = 'get', isFormData: boolean = false) {
        let headers = new HttpHeaders();
        let body;

        if (postData instanceof FormData) {
            headers = headers.set('Accept', 'application/json').set('Language', this.activeLangs);
            body = postData;
        } else {
            headers = headers
                .set('Content-Type', 'application/json')
                .set('Accept', 'application/json')
                .set('Language', this.activeLangs);
            body = JSON.stringify(postData);
        }

        let options = {
            headers: headers,
            observe: 'response' as 'response'
        };

        let result;
        switch (typeMethod) {
            case 'get':
                result = this.http.get(apiUrl + method, options);
                break;
            case 'post':
                result = this.http.post(apiUrl + method, body, options);
                break;
            case 'put':
                result = this.http.put(apiUrl + method, body, options);
                break;
            case 'delete':
                result = this.http.delete(apiUrl + method, options);
                break;
            default:
                result = this.http.post(apiUrl + method, body, options);
        }





        // return result;

        return  result.pipe(
            tap((res: any) => {
                let languageVersionApi = res.headers.get('Language-Version');
                let languageVersionApp = localStorage.getItem('langVersionApp');
                let language = localStorage.getItem('language');

                if (language && languageVersionApi && languageVersionApp &&  languageVersionApp !=  languageVersionApi){
                    localStorage.setItem('langVersionApi',languageVersionApi);
                    this.loadTranslation(language) ;
                }
            }),
            map((res: any) => res.body)
        )
    }

    private loadTranslation(lang: string) {
        of(lang).pipe(
            debounceTime(300),  // Debounce the translation load by 300ms
            switchMap((lang) => this.translocoHttpLoader.getTranslation(lang).pipe(tap(res=>{
                this.translocoService.setTranslation(res,lang);
            }))),  // Load translation
            take(1)  // Take one emission and complete
        ).subscribe(translation => {

        });
    }

}
